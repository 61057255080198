import { Platform } from "./UserOrders"

export type PsRegion = 'SIEA' | 'SIEE' | 'SIEJA-Asia' | 'SIEJA-Japan'
const psMap: Record<PsRegion, string[]> = {
    "SIEA": ["Argentina", "Bolivia", "Brazil", "Canada", "Chile", "Colombia", "Costa Rica", "Ecuador", "El Salvador", "Guatemala", "Honduras", "Mexico", "Nicaragua", "Panama", "Paraguay", "Peru", "United States of America", "Uruguay"],
    "SIEE": ["Australia", "Austria", "Bahrain", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czech Republic", "Denmark", "Finland", "France", "Germany", "Greece", "Hungary", "Iceland", "India", "Ireland", "Israel", "Italy", "Kuwait", "Lebanon", "Luxembourg", "Malta", "Netherlands", "New Zealand", "Norway", "Oman", "Poland", "Portugal", "Qatar", "Romania", "Russian Federation", "Saudi Arabia", "Slovakia", "Slovenia", "South Africa", "Spain", "Sweden", "Switzerland", "Türkiye", "Ukraine", "United Arab Emirates", "United Kingdom"],
    "SIEJA-Asia": ["Indonesia", "Malaysia", "Singapore", "South Korea", "Taiwan", "Thailand"],//[ 'China', 'Hong Kong' ],
    "SIEJA-Japan": ["Japan"],
}

export type NxRegion = 'Japan' | 'Americas' | 'Europe/Australia' | 'Korea' | 'Hong Kong' | 'China'
const nxMap: Record<NxRegion, string[]> = {
    'Japan': ['Japan'],
    'Americas': ['United States', 'Canada', 'Mexico', 'Brazil', 'Argentina', 'Colombia', 'Chile', 'Peru'],
    'Europe/Australia': ['Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Romania', 'Russia', 'Slovak Republic', 'Slovenia', 'South Africa', 'Spain', 'Sweden', 'Switzerland', 'United Kingdom', 'Australia', 'New Zealand'],
    'Korea': ['Korea'],
    'Hong Kong': [],//[ 'Hong Kong' ],
    'China': [],//[ 'China' ]
}

export type CountryWithRegion = { country: string, region: string }

export const GetCountries = (platform: Platform): CountryWithRegion[] => {
    var map: Record<string, string[]> | undefined = (platform == 'Playstation') ? psMap
        : (platform == 'Switch') ? nxMap
            : undefined;

    if (map == undefined) return [];

    var countries: CountryWithRegion[] = [];
    for (var k of Object.keys(map)) {
        for (var c of map[k]) {
            countries = [...countries, { country: c, region: k }];
        }
    }
    countries = countries.sort((a, b) => sortAlphabetically(a.country, b.country));
    return countries;
}

const sortAlphabetically = (a: string, b: string) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}