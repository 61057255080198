import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Snackbar from "@material-ui/core/Snackbar"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import React, { useState } from "react"
import axios from "axios"
import Fade from "@material-ui/core/Fade"
import { MenuItem } from "@material-ui/core"
import config from "../../website-config"

const pledgeSource = ["Kickstarter", "Shopify"];
const pledgeRewards = [
    { id: 9177, name: "The first settler" },
    { id: 9176, name: "Early Bird: The first settler" },
    { id: 9178, name: "Flute player" },
    { id: 9179, name: "Be-ta Shaman" },
    { id: 9180, name: "The First & Second Settlers" },
    { id: 9181, name: "My House Rocks" },
    { id: 9183, name: "AL-Fire keeper" },
    { id: 9182, name: "Early Bird: AL-Fire keeper" },
    { id: 9184, name: "Weaver" },
    { id: 9186, name: "Gatherer" },
    { id: 9185, name: "Early Bird: Gatherer" },
    { id: 9187, name: "Barber of manes" },
    { id: 9188, name: "Woodworker" },
    { id: 9189, name: "Tailor" },
    { id: 9190, name: "Animal Tamer" },
    { id: 9191, name: "Wise Elder" },
]



const validator = require("email-validator");

type Props = {
    onBack: () => void
}

export const UseBackerID: React.FC<Props> = ({ onBack }) => {
    const [failedSnackOpen, setFailedSnackOpen] = useState<[boolean, string?]>([false, undefined])

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const [backerID, setBackerID] = useState('')
    const [backerIDError, setBackerIDError] = useState('')

    const [pledgeTier, setPledgeTier] = useState('')
    const [pledgeTierError, setPledgeTierError] = useState('')

    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)

    return <React.Fragment>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={failedSnackOpen[0]}
            onClose={() => setFailedSnackOpen([false, undefined])}
            autoHideDuration={2000}
            message={failedSnackOpen[1] || 'Oop... there was an error. Please try again.'}
        />
        <Box mb={2}>
            <Paper style={{ padding: 16 }} variant='outlined'>
                <Box mb={3}>
                    <Typography>Enter the email you backed with and your backer number.</Typography>
                </Box>
                <React.Fragment>
                    <TextField disabled={sending} error={emailError != ''} helperText={emailError} onChange={(ev) => {
                        setEmail(ev.currentTarget.value)
                        setEmailError('')
                    }} label={`Email`} size='small' fullWidth variant='outlined' value={email} />
                    <TextField type="number" disabled={sending} error={backerIDError != ''} helperText={backerIDError} onChange={(ev) => {
                        setBackerID(ev.currentTarget.value)
                        setBackerIDError('')
                    }} label={`Backer number`} size='small' fullWidth variant='outlined' margin='normal' value={backerID} />
                    <TextField disabled={sending} select error={pledgeTierError != ''} helperText={pledgeTierError} fullWidth label="Pledge" variant="outlined" margin="normal" value={pledgeTier}
                        onChange={(e: any) => {
                            setPledgeTier(e.target.value)
                            setPledgeTierError('')
                        }}>
                        {
                            pledgeRewards.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
                        }
                    </TextField>
                    <Box mt={1}>
                        <Button disabled={sending} onClick={async () => {
                            const validEmail = validator.validate(email)
                            const validBackerID = backerID != '';
                            const validPledgeTier = pledgeTier != '';
                            if (!validEmail) {
                                setEmailError("Please type a valid email")
                            }

                            if (!validBackerID) {
                                setBackerIDError("This field is required")
                            }

                            if (!validPledgeTier) {
                                setPledgeTierError("This field is required")
                            }

                            if (validEmail && validBackerID && validPledgeTier) {
                                setSending(true);

                                const backerParam = backerID.length > 5 ? `pid=${backerID}` : `bid=${backerID}`

                                try {
                                    const result = await axios.get(`${config.functionsURL}/orders/magiclinknoemail?email=${email}&${backerParam}&rid=${pledgeTier}`)
                                    if (result.status == 200 && (typeof result.data.link === 'string')) {
                                        window.location = result.data.link;
                                    } else {
                                        if (result.status == 401) {
                                            setFailedSnackOpen([true, "We don't have records for that email. Contact support@rootsofpacha.com"])
                                        } else {
                                            setFailedSnackOpen([true, undefined])
                                        }
                                        setSending(false)
                                    }
                                } catch (e) {
                                    setFailedSnackOpen([true, (e as any).response.status == 401 ? "We don't have records for that email. Contact support@rootsofpacha.com" : undefined])
                                    setSending(false)
                                }
                            }
                        }} fullWidth variant='contained' color='primary' value={email}>
                            Login
                        </Button>
                    </Box>
                    <Box mt={1}>
                        <Button disabled={sending} fullWidth variant='text' onClick={onBack}>
                            Back
                        </Button>
                    </Box>
                </React.Fragment>
                {
                    sent && <Fade in={sent}>
                        <Box mt={2}><Typography variant='body1' style={{ textAlign: 'center' }}>🔥 Email sent. Check your inbox! 🔥</Typography></Box>
                    </Fade>
                }
            </Paper>
        </Box>
    </React.Fragment>
}