import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Snackbar from "@material-ui/core/Snackbar"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { colors } from '../../styles/colors'

import React, { useState } from "react"
import axios from "axios"
import Fade from "@material-ui/core/Fade"

const validator = require("email-validator");

type Props = {
    onUseBackerID: () => void
}

export const ResendLink: React.FC<Props> = ({ onUseBackerID }) => {
    const [failedSnackOpen, setFailedSnackOpen] = useState<[boolean, string?]>([false, undefined])

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)

    return <React.Fragment>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={failedSnackOpen[0]}
            onClose={() => setFailedSnackOpen([false, undefined])}
            autoHideDuration={2000}
            message={failedSnackOpen[1] || 'Oop... there was an error. Please try again.'}
        />
        <Box mb={2}>
            <Paper style={{ padding: 16 }} variant='outlined'>
                <Box mb={3}>
                    <Typography paragraph={true} variant="h6">Thank you for supporting the Roots of Pacha Kickstarter! We’re excited for you to play!</Typography>
                    <Typography>If you backed at The First Settler tier or above, enter the email you backed with to manage your keys and follow the instructions.</Typography>
                </Box>
                <React.Fragment>
                    <TextField disabled={sending} error={emailError != ''} helperText={emailError} onChange={(ev) => {
                        setEmail(ev.currentTarget.value)
                        setEmailError('')
                    }} label={`Email`} size='small' fullWidth variant='outlined' value={email} />
                    <Box mt={1}>
                        <Button disabled={sending} onClick={async () => {
                            const validEmail = validator.validate(email)
                            console.log(validEmail)
                            if (!validEmail) {
                                setEmailError("Please type a valid email")
                            } else {
                                setSending(true)
                                try {
                                    const result = await axios.get(`https://us-central1-roots-of-pacha-pledgebox.cloudfunctions.net/orders/magiclink?email=${email}`)
                                    console.log(result.status)
                                    if (result.status === 200) {
                                        setSent(true)
                                    } else {
                                        setFailedSnackOpen([true, result.status == 401 ? "We don't have records for that email. Contact support@rootsofpacha.com" : undefined])
                                        setSending(false)
                                    }
                                } catch(e) {
                                    setFailedSnackOpen([true, (e as any).response.status == 401 ? "We don't have records for that email. Contact support@rootsofpacha.com" : undefined])
                                    setSending(false)
                                }
                            }
                        }} fullWidth variant='contained' color='primary' value={email}>
                            {sent ? 'Sent' : sending ? 'Sending' : 'Resend magic link'}
                        </Button>
                    </Box>
                    <Box mt={1}>
                        <Button fullWidth variant='text' onClick={onUseBackerID}>
                            Didn't get the email?
                        </Button>
                    </Box>
                </React.Fragment>
                {
                    sent && <Fade in={sent}>
                        <Box mt={2}><Typography variant='body1' style={{ textAlign: 'center' }}>🔥 Email sent. Check your inbox! 🔥</Typography></Box>
                    </Fade>
                }
            </Paper>
        </Box>
    </React.Fragment>
}