import qs from "qs"
import React, { useEffect, useState } from "react"
import { Platform, UserOrder, UserOrderData } from "../components/Orders/UserOrders"

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

import axios from "axios";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Logo } from "../components/Logo";
import { colors } from "../styles/colors";
import { ResendLink } from "../components/Orders/ResendLink";

import "../styles/globals.css"
import { SiteHelmet } from "../components/Helmet";
import Helmet from "react-helmet";

// @ts-ignore
import favicon from '../favicon.ico';
import { UseBackerID } from "../components/Orders/UseBackerID";
import { Snackbar } from "@material-ui/core";
import config from "../website-config";
//import { fakeOrder } from "../components/Orders/fakeOrder";

type SearchQuery = {
    token: string
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.logoOrange
        }
    },
    typography: {
        h3: { fontFamily: 'LondrinaSolid' },
        h5: { fontFamily: 'Rubik' },
        h6: { fontFamily: 'Rubik' },
    }
});

const OrdersPage: React.FunctionComponent = () => {

    let locationSearch = ""
    if (typeof window !== 'undefined') {
        locationSearch = window.location.search
    }

    let query = qs.parse(locationSearch, { ignoreQueryPrefix: true }) as SearchQuery
    let { token } = query

    const [fetching, setFetching] = useState(true);
    const [useBackerId, setUseBackerID] = useState(false);
    const [userData, setUserData] = useState<UserOrderData | undefined>(undefined);

    const [errorOpen, setErrorOpen] = React.useState(false);


    const [platformChangeRequested, setPlatformChangeRequested] = useState<{
        index: number,
        platform: Platform
        country: string
    } | undefined>(undefined);

    // fetch data only if token changes.
    useEffect(() => {
        const fetchData = async () => {
            if (!token) {
                setUserData(undefined)
                setFetching(false)
            } else {
                setFetching(true);
                try {
                    const response = await axios.get(`${config.functionsURL}/orders/keys?token=${token}`)
                    if (response.status === 200) {
                        setUserData(response.data)
                    }
                    setFetching(false);
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        if (error.response.status == 401) {
                            console.log('Invalid token')
                        }
                    } else {
                        console.log('Error fetching data', error);
                    }
                    setErrorOpen(true)
                    setFetching(false);
                }
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const setAndFetchData = async () => {
            if (platformChangeRequested) {
                setFetching(true);
                try {
                    let response = await axios.get(
                        `${config.functionsURL}/orders/change-to-platform?token=${token}&index=${platformChangeRequested.index}&platform=${platformChangeRequested.platform}&country=${platformChangeRequested.country}`
                    )

                    if (response.status !== 200) {
                        console.log("Couldn't update platform")
                        setErrorOpen(true)
                    }

                    response = await axios.get(
                        `${config.functionsURL}/orders/keys?token=${token}`
                    )
                    if (response.status === 200) {
                        setUserData(response.data)
                    }
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        if (error.response.status == 401) {
                            console.log('Invalid token')
                        }
                    } else {
                        console.log('Error fetching data', error);
                    }
                    setErrorOpen(true)
                } finally {
                    setFetching(false);
                    setPlatformChangeRequested(undefined)
                }
            }
        }
        setAndFetchData()
    }, [platformChangeRequested])

    // DEV ONLY
    // if (!userData) {
    //     setUserData(fakeOrder)
    // }

    return <React.Fragment>
        <Helmet>
            <link rel="icon" href={favicon} type="image/x-icon" />
        </Helmet>
        <SiteHelmet />
        <ThemeProvider theme={theme}>
            <Container maxWidth='md' style={{ padding: 32 }}>
                <a href={"/"}>
                    <Logo style={{ maxWidth: 180, margin: 'auto', display: 'block' }} />
                </a>
                <Box alignItems='center' mb={2}>
                    <Typography style={{ textAlign: 'center', color: colors.logoOrange }} variant='h3'>Welcome Pachan!</Typography>
                </Box>
                {
                    fetching && <Fade in={fetching}>
                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            <Box mb={2}>
                                <CircularProgress />
                            </Box>
                            <Typography>Fetching data...</Typography>
                        </Box>
                    </Fade>
                }
                {
                    !fetching && userData && <Fade in={userData != undefined}>
                        <UserOrder data={userData} onUpdatePlatform={(index: number, platform: Platform, country: string) => setPlatformChangeRequested({ index, platform, country })} />
                    </Fade>
                }
                {
                    !fetching && !userData && !useBackerId && <Fade in={!fetching && userData == undefined && !useBackerId}>
                        <ResendLink onUseBackerID={() => setUseBackerID(true)} />
                    </Fade>
                }
                {
                    !fetching && !userData && useBackerId && <Fade in={!fetching && userData == undefined && useBackerId}>
                        <UseBackerID onBack={() => setUseBackerID(false)} />
                    </Fade>
                }
                <Snackbar
                    open={errorOpen}
                    autoHideDuration={3000}
                    onClose={() => setErrorOpen(false)}
                    message="An error has occured, try again later"
                />
            </Container>
        </ThemeProvider>
    </React.Fragment>
}

export default OrdersPage